import { get } from 'svelte/store';
import { postsStore } from '../stores/pagination';

interface PostsResponse {
    data: {
        data: any[];
        next_page_url: string | null;
        hasMore: boolean;
    };
    success: boolean;
}

export async function loadMorePosts() {
    let state = get(postsStore);
    if (!state.nextPageUrl || !state.hasMore || state.loading) return;

    postsStore.setLoading(true);

    try {
        const response = await fetch(state.nextPageUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch posts');
        }

        const { data: result }: PostsResponse = await response.json();

        postsStore.append(
            result.data,
            result.next_page_url,
            result.hasMore
        );
    } catch (error) {
        postsStore.setError(error instanceof Error ? error.message : 'Failed to load posts');
    }
}
